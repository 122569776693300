export function getSortedSlots(slots) {
  const newSlots = [...slots];
  return newSlots.sort((slotA, slotB) => new Date(slotA.begin) - new Date(slotB.begin));
}

export function getGroupedSlots(slots) {
  const sortedSlots = getSortedSlots(slots);
  let groupedSlots = [];
  groupedSlots = sortedSlots.reduce((accSlots, slot) => {
    if (accSlots.length === 0) return [{ id: 0, begin: slot.begin, end: slot.end }];
    const newAccSlots = [...accSlots];
    if (new Date(slot.begin).getTime() === new Date(accSlots[accSlots.length - 1].end).getTime()) {
      newAccSlots[accSlots.length - 1].end = slot.end;
    } else {
      newAccSlots.push({ id: newAccSlots.length, begin: slot.begin, end: slot.end });
    }
    return newAccSlots;
  }, []);
  return groupedSlots;
}

export function getGroupedSlotsPerDay(slots) {
  const groupedSlots = getGroupedSlots(slots);
  let groupedSlotsPerDay = [];
  groupedSlotsPerDay = groupedSlots.reduce((accSlots, slot) => {
    if (accSlots.length === 0) return [{ id: 0, slots: [{ begin: slot.begin, end: slot.end }] }];
    const newAccSlots = [...accSlots];
    if (new Date(slot.begin).toDateString() === new Date(accSlots[accSlots.length - 1].slots[0].begin).toDateString()) {
      newAccSlots[accSlots.length - 1].slots.push({ begin: slot.begin, end: slot.end });
    } else {
      newAccSlots.push({ id: newAccSlots.length, slots: [{ begin: slot.begin, end: slot.end }] });
    }
    return newAccSlots;
  }, []);
  return groupedSlotsPerDay;
}

export function getPreFilledSteps(typeOfWork, categoryOrCloth, piece = '', style = undefined) {
  let selectedCategory = '';
  let selectedCloth = '';
  let selectedPiece = '';
  let selectedTypeOfWork = '';
  let selectedFabric = '';

  const isDeco = ['deco', 'mais'].includes(categoryOrCloth);
  selectedCategory = isDeco ? 'deco' : 'clothes';

  switch (typeOfWork) {
    case 'TRANSFORMATION': selectedTypeOfWork = 'transformation'; break;
    case 'RETOUCHE': selectedTypeOfWork = 'alteration'; break;
    case 'REPARATION': selectedTypeOfWork = 'repair'; break;
    case 'CREATION': selectedTypeOfWork = 'creation'; break;
    default: selectedTypeOfWork = style === 'broderie' ? 'embroidery' : '';
  }

  if (selectedTypeOfWork && selectedTypeOfWork !== 'creation') {
    if (isDeco) {
      selectedPiece = piece;
    } else if (categoryOrCloth !== 'dive') {
      selectedCloth = categoryOrCloth;
    }
  }

  if (selectedTypeOfWork && selectedCloth && style === 'denim') selectedFabric = 'jeans';
  return {
    selectedCategory, selectedCloth, selectedPiece, selectedTypeOfWork, selectedFabric,
  };
}

export const getIsClothesWithOnlyRemaillage = (clothes) => clothes.length > 0
  ? clothes.every((cloth) => cloth.items.every((item) => item.id === `13.${cloth.slug}-rep-tear-7`))
  : false;
