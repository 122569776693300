// cspell:disable

const testConfig = {
  API_URL: 'http://localhost:8000/api',
  googleAnalyticsTrackingId: '',
  stripePublishableKey: 'pk_test_UwbkjBtE2W9DjZAHk8gFsALM',
  environment: 'TEST',
};

let apiURL = 'http://localhost:8000/api';

if (process.env.GATSBY_PROD_LIKE_HOST && process.env.GATSBY_PROD_LIKE_PORT) {
  /**
   * The next environment variables have been declared in package.json
   *
   * ```shell
   * yarn run start:https
   * ```
   *
   * process.env.GATSBY_PROD_LIKE_HOST = 'local.api.tilli.fr'
   * process.env.GATSBY_PROD_LIKE_PORT = '8443'
   */
  apiURL = `https://${process.env.GATSBY_PROD_LIKE_HOST}:${process.env.GATSBY_PROD_LIKE_PORT}/api`;
}

const devConfig = {
  API_URL: apiURL,
  googleAnalyticsTrackingId: 'UA-84397323-4',
  stripePublishableKey: 'pk_test_UwbkjBtE2W9DjZAHk8gFsALM',
  environment: 'DEVELOPMENT',
};

const stagingConfig = {
  API_URL: 'https://api.staging.tilli.fr/api',
  googleAnalyticsTrackingId: 'UA-84397323-4',
  stripePublishableKey: 'pk_test_UwbkjBtE2W9DjZAHk8gFsALM',
  environment: 'STAGING',
};

const prodConfig = {
  API_URL: 'https://api.tilli.fr/api',
  googleAnalyticsTrackingId: 'UA-84397323-1',
  stripePublishableKey: 'pk_live_51MlCR9GmCHvZu33ffcjioXcfL1fHYi3vU2IIedemduxxFLFwftolnoC92TuYsT9cz6kJfZfNxKrajWsEGC9DZ4AT00eyzvVI4T',
  environment: 'PRODUCTION',
};

let config;
if (process.env.GATSBY_ENV === 'STAGING') {
  config = stagingConfig;
} else if (process.env.GATSBY_ENV === 'PROD') {
  config = prodConfig;
} else {
  config = devConfig;
}

/**
 * TODO: [POC] Payment System Provider |
 * Not included in conditional structure above
 * to prevent breaking something
 */
if (process.env.JEST_WORKER_ID) {
  config = testConfig;
}

// cSpell:ignore deqmjoae
const cloudinaryCloudName = 'deqmjoae1';

const {
  API_URL, googleAnalyticsTrackingId, environment, stripePublishableKey,
} = config;

export {
  API_URL,
  environment,
  googleAnalyticsTrackingId,
  cloudinaryCloudName,
  stripePublishableKey,
};
