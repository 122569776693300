/* eslint-disable max-len */

function formatNameForURL(name) {
  return name.toLowerCase().trim().replace(/ /g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, ''); // to remove accents from string
}

function getLocalitySeo({ city, locality, zipcode }) {
  const localityName = locality || city;
  const completedZipcode = (zipcode.length === 2) ? `${zipcode}000` : zipcode;
  return {
    title: `Retouche ${localityName} (${completedZipcode}) : Votre Couturier à ${localityName} - Tilli`,
    description: `Retouche ${localityName} ${zipcode} : Votre Couturier à Domicile pour toutes vos Retouches ✅Disponible 7/7 de 7h à 22h ✓Prix identique à votre Retoucherie de Quartier`,
  };
}

function getArrondissementSeo({ city, zipcode, arrondissement }) {
  return {
    title: `Retouche ${city} ${arrondissement} : Votre Couturier à ${city} ${arrondissement} (${zipcode}) - Tilli`,
    description: `Retouche ${city} ${arrondissement} : Votre Couturier à Domicile pour toutes vos Retouches ✅Disponible 7/7 de 7h à 22h ✓Prix identique à votre Retoucherie de Quartier`,
  };
}

function getArrondissementLeatherGoodsSeo({ city, zipcode, arrondissement }) {
  return {
    title: `Maroquinerie ${city} ${arrondissement} : Votre artisan maroquinier à ${city} ${arrondissement} (${zipcode}) - Tilli`,
    description: `Maroquinerie ${city} ${arrondissement} : Votre artisan disponible à tout moment grâce à l'envoi de colis ✓Prix identique à votre Maroquinerie de Quartier`,
  };
}

function getLocalityLeatherGoodsSeo({ city, locality, zipcode }) {
  const localityName = locality || city;
  const completedZipcode = (zipcode.length === 2) ? `${zipcode}000` : zipcode;
  return {
    title: `Maroquinerie ${localityName} (${completedZipcode}) : Votre artisan maroquinier à ${localityName} - Tilli`,
    description: `Maroquinerie ${localityName} ${zipcode} : Votre artisan disponible à tout moment grâce à l'envoi de colis ✓Prix identique à votre Maroquinerie de Quartier`,
  };
}

function getArrondissementShoesSeo({ city, zipcode, arrondissement }) {
  return {
    title: `Cordonnerie ${city} ${arrondissement} : Votre artisan cordonnier à ${city} ${arrondissement} (${zipcode}) - Tilli`,
    description: `Cordonnerie ${city} ${arrondissement} : Votre artisan disponible à tout moment grâce à l'envoi de colis ✓Prix identique à votre Cordonnerie de Quartier`,
  };
}

function getLocalityShoesSeo({ city, locality, zipcode }) {
  const localityName = locality || city;
  const completedZipcode = (zipcode.length === 2) ? `${zipcode}000` : zipcode;
  return {
    title: `Cordonnerie ${localityName} (${completedZipcode}) : Votre artisan cordonnier à ${localityName} - Tilli`,
    description: `Cordonnerie ${localityName} ${zipcode} : Votre artisan disponible à tout moment grâce à l'envoi de colis ✓Prix identique à votre Cordonnerie de Quartier`,
  };
}

function getLeatherGoodsSeo(seo) {
  if (seo.arrondissement) return getArrondissementLeatherGoodsSeo(seo);
  return getLocalityLeatherGoodsSeo(seo);
}

function getShoesSeo(seo) {
  if (seo.arrondissement) return getArrondissementShoesSeo(seo);
  return getLocalityShoesSeo(seo);
}

function getSeo(seo) {
  if (seo.arrondissement) return getArrondissementSeo(seo);
  return getLocalitySeo(seo);
}

// cSpell:ignore vetement
function getPath({ city, locality, arrondissement }) {
  const formattedCity = formatNameForURL(city);
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return `/retouche-vetement/${formattedCity}/${formattedCity}-${arrondissement2digits}/`.toLowerCase();
  }
  if (locality) {
    const formattedLocality = formatNameForURL(locality);
    return `/retouche-vetement/${formattedCity}/${formattedLocality}/`.toLowerCase();
  }
  return `/retouche-vetement/${formattedCity}/`.toLowerCase();
}

function getLeatherGoodsPath({ city, locality, arrondissement }) {
  const formattedCity = formatNameForURL(city);
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return `/maroquinerie/${formattedCity}/${formattedCity}-${arrondissement2digits}/`.toLowerCase();
  }
  if (locality) {
    const formattedLocality = formatNameForURL(locality);
    return `/maroquinerie/${formattedCity}/${formattedLocality}/`.toLowerCase();
  }
  return `/maroquinerie/${formattedCity}/`.toLowerCase();
}

function getShoesPath({ city, locality, arrondissement }) {
  const formattedCity = formatNameForURL(city);
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return `/cordonnerie/${formattedCity}/${formattedCity}-${arrondissement2digits}/`.toLowerCase();
  }
  if (locality) {
    const formattedLocality = formatNameForURL(locality);
    return `/cordonnerie/${formattedCity}/${formattedLocality}/`.toLowerCase();
  }
  return `/cordonnerie/${formattedCity}/`.toLowerCase();
}

function formatUrlPath(path) {
  return path.toLowerCase().replace(/[&/\\#,+().:*?]/g, '').replace(/['"’_ ]/g, '-');
}

module.exports = {
  formatNameForURL,
  getLocalitySeo,
  getArrondissementSeo,
  getSeo,
  getPath,
  getLeatherGoodsPath,
  getLeatherGoodsSeo,
  formatUrlPath,
  getShoesPath,
  getShoesSeo,
};
